/** */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AudienceModule from './audience/_index';
import PaymentsModule from './payments/_index';
import { BillingModule } from './billing/_index';
import CurrencyListModule from './currency/_index';
import TenantsModule from './tenants/_index';
import SettingsModule from './settings/_index';
import MemberAreaHeader from './header/header';
import GMDashboard from './dashboard';
import CampaignModule from './campaigns/_index';
import ServerModule from './servers/_index';
import { Transactionals } from './transactionals';
import { UserManagementModule } from './user-management';
import { TutorialModule } from './tutorials/_index';

export const ModuleLayout = ({ setOpenMenuTray }) => {
  return (
    <section className="app-content">
      <MemberAreaHeader onHamburgerClick={() => setOpenMenuTray(true)} />
      <Switch>
        <Route path="/audiences" component={AudienceModule} />
        <Route path="/campaigns" component={CampaignModule} />
        <Route path="/payments" component={PaymentsModule} />
        <Route path="/billings" component={BillingModule} />
        <Route path="/currencies" component={CurrencyListModule} />
        <Route path="/tenants" component={TenantsModule} />
        <Route path="/transactionals" component={Transactionals} />
        <Route path="/tutorials" component={TutorialModule} />
        <Route path="/servers" component={ServerModule} />
        <Route path="/settings" component={SettingsModule} />
        <Route path="/user-management" component={UserManagementModule} />
        <Route path="/" component={GMDashboard} />
      </Switch>
    </section>
  );
};
