import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import SideNavItem from './item/item';
import { SiteIcon } from 'assets/icons';

import styles from './side-nav.module.css';

const SideNav = (props) => {
  const { open_tray, onTrayClose } = props;
  const veil = useRef();

  useEffect(() => {
    if (open_tray) {
      openMenuTray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open_tray]);

  const closeMenuTray = () => {
    const tray = veil.current;
    let tray_left = 0;
    fadeOutTrayBackground();
    const slideOutAmination = setInterval(() => {
      if (tray_left > 100) {
        tray.style.left = '100%';
        clearInterval(slideOutAmination);
        onTrayClose();
        return;
      }

      tray_left += 10;
      tray.style.left = `-${tray_left}%`;
    }, 20);
  };

  const fadeInTrayBackground = () => {
    const tray = veil.current;
    let opacity = 0;
    const fadeInAnimation = setInterval(() => {
      if (opacity > 0.3) {
        tray.style.background = 'rgba(0, 0, 0, 0.35)';
        clearInterval(fadeInAnimation);
        return;
      }

      opacity += 0.1;
      tray.style.background = `rgba(0, 0, 0, ${opacity})`;
    }, 10);
  };

  const fadeOutTrayBackground = () => {
    const tray = veil.current;
    let opacity = 0.35;
    const fadeOutAnimation = setInterval(() => {
      if (opacity < 0) {
        tray.style.background = 'rgba(0, 0, 0, 0)';
        clearInterval(fadeOutAnimation);
        return;
      }

      opacity -= 0.1;
      tray.style.background = `rgba(0, 0, 0, ${opacity})`;
    }, 10);
  };

  const openMenuTray = () => {
    const tray = veil.current;
    let tray_left = 100;
    tray.style.background = 'rgba(0, 0, 0, 0)';
    const slideInAmination = setInterval(() => {
      if (tray_left < 0) {
        tray.style.left = 0;
        fadeInTrayBackground();
        clearInterval(slideInAmination);
        return;
      }

      tray_left -= 10;
      tray.style.left = `-${tray_left}%`;
    }, 20);
  };

  return (
    <nav className={styles.sideNavWrapper} ref={veil} onClick={() => closeMenuTray()}>
      <div className={styles.appSideNav} onClick={(e) => e.stopPropagation()}>
        <header>
          <Link to="/dashboard" className={styles.link}>
            <span className={styles.siteIcon}>
              <SiteIcon width={48} height={24} />
            </span>
            <span className={styles.siteName}>
              Go-Mailer<span style={{ color: 'var(--orange-primary)' }}>.</span>
            </span>
          </Link>
        </header>
        {/* <CreateButton actions={icon_new_actions} handleUserAction={handleUserAction} /> */}
        <section>
          <SideNavItem
            title="Dashboard"
            name="dashboard"
            path="/dashboard"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="Audience"
            name="groups"
            path="/audiences"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="Campaigns"
            name="campaign"
            path="/campaigns"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="Transactionals"
            name="transactional"
            path="/transactionals"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="Payments"
            name="balance"
            path="/payments"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="Servers"
            name="dashboard"
            path="/servers"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="Currencies"
            name="balance"
            path="/currencies"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="User Management"
            name="profile"
            path="/user-management/users"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="Tutorials"
            name="tutorial"
            path="/tutorials"
            onItemClick={closeMenuTray}
          />
          <SideNavItem
            title="Billings"
            name="billing"
            path="/billings"
            onItemClick={closeMenuTray}
          />
          <SideNavItem title="Tenants" name="groups" path="/tenants" onItemClick={closeMenuTray} />
          <SideNavItem
            title="Settings"
            name="settings"
            onItemClick={closeMenuTray}
            path="/settings"
          />
        </section>
      </div>
    </nav>
  );
};

export default SideNav;
